<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div style="display: none;">
      <div
        id="popup"
        class="text-center"
      >
        <v-card dark>
          <div class="px-3 pb-3 pt-1 text-left">
            <div class="text-right me-n2">
              <v-icon
                color="red"
                @click="resetMarker()"
              >
                mdi-close
              </v-icon>
            </div>
            <div class="div-overflow-y">
              <p
                v-for="(item, index) in Object.keys(map.selectedFeatureObj)"
                :key="(index)"
                style="line-height: 1rem"
                class="mt-n3"
              >
                <span v-if="!map.ignoreProperties.includes(item)">
                  <b>{{ formatPropertyObj(item) }}</b>: {{ formatDate(item, map.selectedFeatureObj[item]) }}
                  <span v-if="item === 'standard_property'">
                    {{ $t(`${map.selectedFeatureLayerName}_serviceID:${table_chart.vectorChartItem[0].service_id}.udm`).replace('(', '').replace(')', '') }}
                  </span>
                </span>
              </p>
              <vector-layer-popup
                v-if="table_chart.vectorChartItem.length > 0"
                :vector-layers="table_chart.vectorChartItem"
                :service-card-id="table_chart.vectorChartItem[0].service_id"
                :min-step="table_chart.vectorChartItem[0].minStep"
                :max-step="table_chart.vectorChartItem[0].maxStep"
                :service-name="table_chart.vectorChartItem[0].service_name"
                :feature-id="table_chart.vectorChartItem[0].feature_id"
                :is-graph="table_chart.vectorChartItem[0].isGraph"
                :animation-step-date-type="table_chart.vectorChartItem[0].animationStepDateType"
                :animation-step="table_chart.vectorChartItem[0].animationStep"
                :table-chart-range-date="table_chart.tableChartRangeDate[table_chart.vectorChartItem[0].service_id]"
                :is-daily-data="table_chart.vectorChartItem[0].animationStepDateType === 'dynamic_days' || (table_chart.vectorChartItem[0].animationStepDateType === 'dynamic_hours' && table_chart.vectorChartItem[0].animationStep >= 24)"
              />
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment-business-days';

export default {
  components: {
    VectorLayerPopup: () => import('@/components/utils/VectorLayerPopup')
  },
  props: {
  },
  data () {
    return {
      overlay: false
    };
  },
  computed: {
    ...mapState(['map', 'table_chart'])
  },
  watch: {
    $route() {}
  },
  mounted() {
  },
  methods: {
    ...mapActions(['closePopupOnMap']),
    resetMarker() {
      this.closePopupOnMap();
      this.map.select.getFeatures().clear();
    },
    formatPropertyObj(string) {
      if (string === 'datetime_range') {
        string = this.$t('ServiceCard.date');
      }

      if (string === 'standard_property') {
        string = this.$t(`${this.map.selectedFeatureLayerName}_serviceID:${this.table_chart.vectorChartItem[0].service_id}.label`);
      }

      const removeUnderscore = string.replace('_', ' ');

      return `${removeUnderscore.charAt(0).toUpperCase()}${removeUnderscore.slice(1)}`;
    },
    formatDate(item, string) {
      if (item === 'datetime_range') {
        return string.includes('T') ? moment(string).format('YYYY-MM-DD HH:mm') : string.replace('Z', '');
      } else {
        if (moment(string, moment.ISO_8601, true).isValid() && typeof string === 'string') {
          return moment(string).format('YYYY-MM-DD HH:mm');
        }

        return string;
      }
    }
  }
};
</script>

<style scoped>
#popup > .v-icon {
  text-shadow: 0px 3px 5px #333;
}
.v-card {
  max-width: 450px;
  margin-bottom: 15px;
  /* outline-offset: -5px;
  outline: 3px solid #00A644; */
  opacity: 0.95;
  font-family: 'Open Sans', sans-serif;
}
.v-card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  opacity: 0.90;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.div-overflow-y{
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}
</style>
